/**
 * @file pointsSlice.ts
 * @description Slice that stores the points state
 * @author Eric Velepucha
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface PointsState {
  balance: number;
}

const initialState: PointsState = {
  balance: 0,
};

export const pointsSlice = createSlice({
  name: 'points',
  initialState,
  reducers: {
    setPointsBalance: (state, action) => {
      state.balance = action.payload;
    },
    adjustPointsBalance: (state, action: PayloadAction<number>) => {
      state.balance += action.payload;
    },
  },
});

export const { setPointsBalance, adjustPointsBalance } = pointsSlice.actions;
export const selectPointsBalance = (state: RootState) => state.points.balance;
export default pointsSlice.reducer;
