export const ANGEL_SIGN_MESSAGE_DELIM = '<>';

export const ANGEL_SIGN_FIELD_ATTRIBUTE = 'ANGEL SIGN';

/**
 * Returns the part of the link after the last slash
 */
function getLastPathInUrl(link: string) {
  return link.substring(link.lastIndexOf('/') + 1);
}

/**
 * Parses the message string into its message text and envelope hashkey
 * components. Intended for actions with a field attribute of 'ANGEL SIGN'
 * @param message - The message string
 * @returns An object containing the text and hashkey
 */
export function parseAngelSignActionMessage(message: string) {
  // if the delim is not present.
  if (message.indexOf(ANGEL_SIGN_MESSAGE_DELIM) === -1) {
    return {
      text: message,
      hashkey: null
    }
  }

  const [text, link] = message.split(ANGEL_SIGN_MESSAGE_DELIM);
  return {
    text,
    hashkey: getLastPathInUrl(link),
  };
}
