import { useRef } from 'react';
import { useErrandContext } from '@contexts/ErrandContext';
import { getUserId, removeSwipeableBooleanData, setSwipeableBooleanData } from '@storage/userStorage';
import eventBus from '@common/eventBus';
import { BOOLEAN_SWIPE_ANSWER_EVENT } from '@components/MorphSwipeableBooleans/constants';
import { useTranslation } from 'react-i18next';
import axiosCall from '@services/axios';
import { MorphType } from '@common/MorphType';
import { AccessType } from '@common/AccessType';
import { useRootContext } from '@contexts/RootContext';
import { ResetFooterUserAction } from '@common/common';

/**
 * This hook exposes functions for querying and updating the data for the
 * Yes/No list (swipeable boolean) morph type, which comes from the
 * errand context.
 */
export default function useSwipeableBooleanData() {
  const errandContext = useErrandContext();
  const rootContext = useRootContext();
  const { t } = useTranslation();
  const isSubmittingRef = useRef(false);

  /**
   * Saves the answer, either editing the response if the question was already
   * answered or pushing the new answer to the answers array
   *
   * @param question the question string
   * @param answer the answer (Yes/No)
   * @param idx the index of the question
   * @returns true if the answer was submitted, false otherwise. The answer
   * will not be submitted in the case that the user tries to answer the last
   * question but they have previous unanswered questions. In this case, the
   * user will be redirected to the first question they have not yet answered.
   */
  const addOrEditAnswer = async (question: string, answer: 'Yes' | 'No', idx: number, qidCode: string) => {
    if (!errandContext.swipeableBooleanData || isSubmittingRef.current) {
      return;
    }

    // If this is the last question but there is/are previously unanswered
    // question(s), don't submit the answer for this question. Instead,
    // take the user back to the first question they have not answered.
    const needToGoBack = idx === getNumQuestions() - 1 && getNumQuestionsUnanswered() > 1;
    if (needToGoBack) {
      setCurrentQuestionIdx(getFirstUnansweredQuestionIdx());
      return false;
    }
    const isLastQuestion = idx === getNumQuestions() - 1;

    isSubmittingRef.current = true;

    const newData = {
      ...errandContext.swipeableBooleanData,
      answers: [...errandContext.swipeableBooleanData.answers],
    };
    const existingIdx = newData.answers.findIndex((answer) => answer.idx === idx);
    if (existingIdx !== -1) {
      newData.answers[existingIdx] = { question, answer, idx, qidCode };
    } else {
      newData.answers.push({ question, answer, idx, qidCode });
    }

    errandContext.setSwipeableBooleanData(newData);
    // Update local storage
    setSwipeableBooleanData(JSON.stringify(newData));

    // User answered all questions, submit their responses
    if (isLastQuestion) {
      const { message } = errandContext.swipeableBooleanData;
      axiosCall({
        url: `chat/${message.chat}/message`,
        method: 'POST',
        data: {
          sender: getUserId(),
          senderType: 'User',
          accessType: AccessType.public,
          message: JSON.stringify(newData.answers),
          messageType: 'Field',
          userAction: message?.userAction?._id,
        },
      })
        .then(() => {
          errandContext.setSwipeableBooleanData(null);
          // Clear the swipeable boolean data from local storage
          removeSwipeableBooleanData();
          errandContext.setMorphType(MorphType.None);
          rootContext.setErrands((prev) => {
            const chatObj = prev.find((e) => e._id === errandContext.errand?._id);
            if (chatObj) {
              ResetFooterUserAction(chatObj);
            }
            return [...prev];
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      incrementCurrentQuestionIdx();
    }

    isSubmittingRef.current = false;

    return true;
  };

  const incrementCurrentQuestionIdx = () => {
    errandContext.setSwipeableBooleanData((prev) => {
      const newData = {
        ...prev,
        currentQuestionIdx:
          prev.currentQuestionIdx + 1 < getNumQuestions() ? prev.currentQuestionIdx + 1 : prev.currentQuestionIdx,
      };
      // Update local storage
      setSwipeableBooleanData(JSON.stringify(newData));
      return newData;
    });
  };

  const decrementCurrentQuestionIdx = () => {
    errandContext.setSwipeableBooleanData((prev) => {
      const newData = {
        ...prev,
        currentQuestionIdx: prev.currentQuestionIdx === 0 ? 0 : prev.currentQuestionIdx - 1,
      };
      // Update local storage
      setSwipeableBooleanData(JSON.stringify(newData));
      return newData;
    });
  };

  const setCurrentQuestionIdx = (newIdx: number) => {
    errandContext.setSwipeableBooleanData((prev) => {
      const newData = {
        ...prev,
        currentQuestionIdx: newIdx,
      };
      // Update local storage
      setSwipeableBooleanData(JSON.stringify(newData));
      return newData;
    });
  };

  const getCurrentQuestionIdx = () => {
    return errandContext.swipeableBooleanData?.currentQuestionIdx;
  };

  const readyToSubmit = () =>
    errandContext.swipeableBooleanData?.answers?.length === errandContext.swipeableBooleanData?.questions?.length &&
    errandContext.swipeableBooleanData?.currentQuestionIdx >= errandContext.swipeableBooleanData?.questions?.length;

  const canGoForward = () => {
    return (
      errandContext.swipeableBooleanData?.currentQuestionIdx < errandContext.swipeableBooleanData?.questions?.length - 1
    );
  };

  const canGoBackward = () => {
    return errandContext.swipeableBooleanData?.currentQuestionIdx > 0;
  };

  const getNumQuestions = () => {
    return errandContext.swipeableBooleanData?.questions?.length ?? -1;
  };

  const getNumQuestionsUnanswered = () => {
    return errandContext.swipeableBooleanData
      ? errandContext.swipeableBooleanData?.questions?.length - errandContext.swipeableBooleanData?.answers?.length
      : -1;
  };

  const getNumQuestionsAnswered = () => {
    return errandContext.swipeableBooleanData?.answers?.length ?? -1;
  };

  const allQuestionsAnswered = () => {
    return errandContext.swipeableBooleanData && getNumQuestions() > 0 && getNumQuestionsUnanswered() === 0;
  };

  const getFirstUnansweredQuestionIdx = () => {
    return allQuestionsAnswered() ? -1 : errandContext.swipeableBooleanData?.answers?.length ?? -1;
  };

  const getInputPlaceholder = () => {
    return errandContext.swipeableBooleanData
      ? `${getNumQuestions() - getNumQuestionsUnanswered()} / ${getNumQuestions()} ${t('answered')}`
      : `${t('Loading')}...`;
  };

  const broadcastAnswer = (answer: string) => {
    eventBus.dispatch(BOOLEAN_SWIPE_ANSWER_EVENT, { answer });
  };

  const getPlayingInitialAnimation = () => {
    return errandContext.swipeableBooleanData?.playingInitialAnimation;
  };

  const setPlayingInitialAnimation = (playing: boolean) => {
    errandContext.setSwipeableBooleanData((prev) => {
      const newData = {
        ...prev,
        playingInitialAnimation: playing,
      };
      return newData;
    });
  };

  const getQuestions = () => {
    return errandContext.swipeableBooleanData?.questions ?? [];
  };

  return {
    addOrEditAnswer,
    incrementCurrentQuestionIdx,
    decrementCurrentQuestionIdx,
    setCurrentQuestionIdx,
    getCurrentQuestionIdx,
    readyToSubmit,
    canGoForward,
    canGoBackward,
    getNumQuestions,
    getNumQuestionsUnanswered,
    getNumQuestionsAnswered,
    allQuestionsAnswered,
    getFirstUnansweredQuestionIdx,
    getInputPlaceholder,
    broadcastAnswer,
    getPlayingInitialAnimation,
    setPlayingInitialAnimation,
    getQuestions,
  };
}
